<template>
    <section class="shop-delivery-class">
        <tool-bar v-bind:lang.sync="lang"
                  enable-translate
                  v-bind:translate="enableTcToSc">
            {{ mode }} a Delivery Class
            <template slot="buttons">
                <div class="level-item">
                    <button class="button"
                            v-if="mode === 'Create'"
                            v-bind:class="{ 'is-loading': loading }"
                            v-bind:disabled="!allowSave"
                            v-on:click="save">
                        <span class="icon is-small">
                            <font-awesome-icon icon="save"></font-awesome-icon>
                        </span>
                        <span>Save</span>
                    </button>
                    <button class="button"
                            v-if="mode === 'Update'"
                            v-bind:class="{ 'is-loading': loading }"
                            v-bind:disabled="!allowSave"
                            v-on:click="update">
                        <span class="icon is-small">
                            <font-awesome-icon icon="save"></font-awesome-icon>
                        </span>
                        <span>Update</span>
                    </button>
                </div>
            </template>
        </tool-bar>

        <div class="card my-4 mx-5">
            <div class="card-head bg-white p-3">
                <div class="row no-gutters w-100">
                    <div class="col">
                        <h5 style="min-height: 24px; ">{{ title[lang] }}</h5>
                        <table class="table mb-0">
                            <thead>
                            <tr>
                                <th scope="col">
                                    Delivery Method
                                </th>
                                <th scope="col">
                                        <span v-if="conditions[0].sign === '>' && conditions[0].includeEqual">
                                            Purchase less than HK${{ conditions[0].value }}
                                        </span>
                                    <span v-if="conditions[0].sign === '>' && !conditions[0].includeEqual">
                                            Purchase HK${{ conditions[0].value }} or less
                                        </span>
                                </th>
                                <th scope="col">
                                        <span v-if="conditions[0].sign === '>' && conditions[0].includeEqual">
                                            Purchase HK${{ conditions[0].value }} or above
                                        </span>
                                    <span v-if="conditions[0].sign === '>' && !conditions[0].includeEqual">
                                            Purchase more than HK${{ conditions[0].value }}
                                        </span>
                                </th>
                                <th scope="col">
                                    Delivery Time (Working Days)
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="method in methods">
                                <td>{{ method.name[lang] }}</td>
                                <td>
                                    <span>HK${{ method.price.value }}</span>
                                </td>
                                <td>Free</td>
                                <td>{{ method.deliveryTime.from }}-{{ method.deliveryTime.to }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <b-field horizontal label="Title">
                    <b-input name="title" expanded
                             v-model="title[lang]"
                             v-on:input="translateTCToSC(title)">
                    </b-input>
                </b-field>

                <b-field horizontal
                         label="Eligible countries"
                         v-if="!isDefault">
                    <b-taginput expanded
                                placeholder="Add a country"
                                icon="label"
                                autocomplete
                                v-bind:allow-new="false"
                                field="name.en"
                                v-model="countries"
                                v-bind:data="filteredCountries"
                                v-on:typing="getFilteredCountries">
                    </b-taginput>
                </b-field>

                <b-field horizontal label="Free shipping condition">
                    <div class="content">
                        <b-field grouped style="margin-bottom: 1rem; ">
                            <b-field label="Field">
                                <b-select v-model="conditions[0].field">
                                    <option value="total">
                                        Order Total
                                    </option>
                                </b-select>
                            </b-field>

                            <b-field label="Operator" expanded>
                                <b-field expanded>
                                    <b-radio-button v-model="conditions[0].sign"
                                                    native-value=">">
                                        > <span>Larger than</span>
                                    </b-radio-button>

                                    <b-checkbox-button v-model="conditions[0].includeEqual">
                                        = <span>Equal to</span>
                                    </b-checkbox-button>

                                    <!-- <b-radio-button v-model="deliveryClass.conditions[0].sign"
                                                    native-value="<">
                                        < <span>Smaller than</span>
                                    </b-radio-button> -->
                                </b-field>
                            </b-field>

                            <b-field label="Value" expanded>
                                <b-input v-model="conditions[0].value"></b-input>
                            </b-field>
                        </b-field>
                    </div>
                </b-field>

                <div class="level" style="margin-top: 1.5rem; margin-bottom: 0.5rem;">
                    <div class="level-left">
                        <div class="level-item">
                            <h5 class="title">Delivery Methods</h5>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item">
                            <button class="button"
                                    v-on:click="addMethod()">
                                    <span class="icon is-small">
                                        <font-awesome-icon icon="plus"></font-awesome-icon>
                                    </span>
                                <span>Add a delivery method</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card"
                     style="padding: 1rem; padding-bottom: 0; margin-bottom: 0.75rem; "
                     v-for="( method, index ) in methods">
                    <b-field horizontal label="Method Name">
                        <b-input name="name" expanded
                                 v-model="method.name[lang]"
                                 v-on:input="translateTCToSC(method.name)">
                        </b-input>
                    </b-field>

                    <b-field horizontal
                             grouped
                             label="Delivery Time (in Working Days)">
                        <b-field label="From">
                            <b-input name="working-days-from" expanded
                                     v-model="method.deliveryTime.from">
                            </b-input>
                        </b-field>
                        <b-field label="To">
                            <b-input name="working-days-to" expanded
                                     v-model="method.deliveryTime.to">
                            </b-input>
                        </b-field>
                    </b-field>

                    <b-field grouped>
                        <b-field label="Delivery cost type">
                            <b-select v-model="method.price.type">
                                <option value="lumpsum">Lump sum</option>
                                <!-- <option value="advalorem">Ad valorem</option> -->
                            </b-select>
                        </b-field>

                        <b-field label="Delivery cost"
                                 expanded
                                 v-if="method.price.type === 'lumpsum'">
                            <b-input placeholder="HK$00.00"
                                     v-model="method.price.value">
                            </b-input>
                        </b-field>

                        <b-field label="Delivery cost"
                                 expanded
                                 v-if="method.price.type === 'advalorem'">
                            <b-field>
                                <b-input placeholder="HK$00.00" expanded
                                         v-model="method.price.value">
                                </b-input>
                                <!-- <b-select v-mode="condition.price.unit">
                                    <option value="weight">Per KG</option>
                                    <option value="total">Per HKD</option>
                                </b-select> -->
                            </b-field>
                        </b-field>
                    </b-field>

                    <b-field v-if="index > 0">
                        <p class="control" style="text-align: right; ">
                            <button class="button is-danger"
                                    v-on:click="removeMethod(index)">
                                Delete
                            </button>
                        </p>
                    </b-field>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import countries from "../../assets/country.json";
import ToolBar from "../layouts/ToolBar";

const opencc = require("node-opencc");

export default {
    name: "DeliveryClass",
    components: {
        ToolBar
    },
    data() {
        return {
            lang: "en",
            enableTcToSc: true,
            mode: "Create",
            loading: false,

            filteredCountries: countries,

            isDefault: false,
            title: {
                "en": "",
                "zh-hk": "",
                "zh-cn": ""
            },
            countries: [],
            conditions: [{
                field: "total",
                sign: ">",
                includeEqual: false,
                value: ""
            }],
            methods: [{
                name: {
                    "en": "",
                    "zh-hk": "",
                    "zh-cn": ""
                },
                deliveryTime: {
                    from: "",
                    to: ""
                },
                price: {
                    type: "lumpsum",
                    value: "",
                    unit: "weight"
                }
            }]
        }
    },
    computed: {
        allowSave () {
            return this.title["en"] &&
                this.title["zh-hk"] &&
                this.title["zh-cn"] &&
                this.countries.length > 0 &&
                this.conditions &&
                this.conditions[0] &&
                this.conditions[0].field &&
                this.conditions[0].sign &&
                this.conditions[0].hasOwnProperty("includeEqual") &&
                this.conditions[0].value &&
                this.methods.reduce(
                    (acc, method) => acc &&
                        method.name &&
                        method.name["en"] &&
                        method.name["zh-hk"] &&
                        method.name["zh-cn"] &&
                        method.deliveryTime &&
                        method.deliveryTime.from &&
                        method.deliveryTime.to &&
                        method.price &&
                        method.price.type &&
                        method.price.value &&
                        method.price.unit,
                    true
                )
        }
    },
    methods: {
        getFilteredCountries(text) {
            this.filteredCountries = countries.filter(
                (country) => country.name["en"]
                    .toString()
                    .toLowerCase()
                    .indexOf(text.toLowerCase()) >= 0
            )
        },

        translateTCToSC(multiLangItem) {
            if (this.enableTcToSc) {
                if (this.lang === "zh-hk") {
                    multiLangItem["zh-cn"] = opencc.traditionalToSimplified(multiLangItem["zh-hk"])
                }
            }
        },

        addMethod() {
            this.methods.push(
                {
                    "name": {
                        "en": "",
                        "zh-hk": "",
                        "zh-cn": ""
                    },
                    "deliveryTime": {
                        "from": "",
                        "to": ""
                    },
                    "price": {
                        "type": "lumpsum",
                        "value": "",
                        "unit": "weight"
                    }
                }
            );
        },

        removeMethod(index) {
            if (index > 0) {
                this.methods.splice(index, 1)
            }
        },

        async save() {
            if (this.allowSave === true) {
                this.loading = true
                try {
                    await this.$store
                        .dispatch(
                            "shop/addDeliveryClass",
                            {
                                "title": this.title,
                                "countries": this.countries,
                                "conditions": this.conditions,
                                "methods": this.methods
                            }

                        );
                } catch (e) {
                    console.error(e)
                    this.loading = false
                    return
                }
                this.loading = false
                this.$router.push("/shop/delivery")
            }
        },

        async update() {
            this.loading = true
            try {
                await this.$store
                    .dispatch(
                        "shop/updateDeliveryClass",
                        {
                            "_id": this.$route.params.classId,
                            "title": this.title,
                            "countries": this.countries,
                            "conditions": this.conditions,
                            "methods": this.methods
                        }
                    );
            } catch (e) {
                console.error(e)
                this.loading = false
                return
            }
            this.loading = false
        }
    },
    mounted() {
        const deliveryClass = this.$store.state.shop.deliveryClasses
            .find(
                ({ _id }) => _id === this.$route.params.classId
            );
        if (deliveryClass) {
            this.isDefault = deliveryClass.default;
        }
    },
    watch: {

    }
}
</script>

<style lang="scss" scoped>
$theme-colors: (
    "dark": rgba(113, 113, 113, 1)
);
@import "~bootstrap/scss/bootstrap";

.shop-delivery-class {

}
</style>

<template>
    <div class="toolbar level">
        <div class="level-left">
            <div class="level-item">
                <h3 class="label">
                    <slot></slot>
                </h3>
            </div>
        </div>
        <div class="level-right">
            <div class="level-item" v-if="hasSearch">
                <b-input placeholder="🔎 Search by keywords, names, or number"
                         style="width: 350px; "
                         v-model="name">
                </b-input>
            </div>
            <div class="level-item">
                <lang-selection-button-group v-if="lang"
                                             v-bind:lang.sync="lang"
                                             v-bind:has-translate="enableTranslate"
                                             v-bind:translate="translate">
                </lang-selection-button-group>
            </div>
            <slot name="buttons"></slot>
        </div>
    </div>
</template>

<script>
import LangSelectionButtonGroup from "../LangSelectionButtonGroup";

export default {
    name: "ToolBar",
    components: {
        LangSelectionButtonGroup
    },
    props: {
        title: {
            type: String
        },
        lang: {
            type: String
        },
        hasSearch: {
            type: Boolean
        },
        enableTranslate: {
            type: Boolean,
            default: false
        },
        translate: {
            type: Boolean
        },
    },
    watch: {
        'lang'(newVal) {
            this.$emit('update:lang', newVal)
        },

        'enableTranslate'(newVal) {
            this.$emit('update:enableTranslate', newVal)
        }
    }
}
</script>

<style lang="scss" scoped>
.toolbar {
    background-color: rgba(255, 255, 255, 1);
    padding: 0.75rem 1rem;
    margin-bottom: 0;
    box-shadow: 0 0 1px rgba(125, 125, 125, 1);

    position: sticky;
    top: 0;
    z-index: 9;

    .label {
        font-size: 20px;
        margin-bottom: 0;
    }
}
</style>

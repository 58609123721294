<template>
    <div class="field has-addons">
        <p class="control">
            <a class="button"
               v-bind:class="{ 'is-active': lang === 'en' }"
               v-on:click="setLanguage('en')">
                <span>English</span>
            </a>
        </p>
        <p class="control">
            <a class="button"
               v-bind:class="{ 'is-active': lang === 'zh-hk' }"
               v-on:click="setLanguage('zh-hk')">
                <span>繁體中文</span>
            </a>
        </p>
        <p class="control">
            <a class="button"
               v-bind:class="{ 'is-active': lang === 'zh-cn' }"
               v-on:click="setLanguage('zh-cn')">
                <span>簡體中文</span>
            </a>
        </p>
        <div class="control"
           v-if="hasTranslate">
            <b-dropdown position="is-bottom-left">
                <button class="button" slot="trigger">
                    <font-awesome-icon icon="angle-down"></font-awesome-icon>
                </button>

                <b-dropdown-item custom>
                    <section>
                        <div class="field">
                            <b-switch type="is-success"
                                      v-model="doTranslate">
                                將繁體中文翻譯成簡體中文
                            </b-switch>
                        </div>
                    </section>
                </b-dropdown-item>
            </b-dropdown>
        </div>
    </div>
</template>

<script>
export default {
    name: "LangSelectionButtonGroup",
    props: {
        lang: {
            type: String,
            required: true
        },

        allowedLang: {
            type: Array,
            default: () => []
        },

        hasTranslate: {
            type: Boolean,
            default: false
        },

        translate: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        doTranslate: {
            get () {
                return this.translate;
            },
            set (val) {
                this.$emit("update:translate", val);
            }
        }
    },
    methods: {
        setLanguage (lang) {
            if (this.allowedLang.length > 0) {
                if (!this.allowedLang.includes(lang)) {
                    return;
                }
            }
            this.$emit("update:lang", lang);
        },
    }
}
</script>

<style lang="scss" scoped>
.control {
    margin-bottom: 0;

    & > .button {
        &.is-active {
            background-color: #4a4a4a;
            color: white;
        }
    }
}
</style>
